import Swiper, { Autoplay } from 'swiper';
import { MoveSlideToEnd } from "./MoveSlideToEnd";

export default {
    init: function(){
        if(document.querySelector("#home-swiper") !== null) {
            const el = document.querySelector("#home-swiper");
            const isMobile = window.outerWidth < 992;

            el.swiper = new Swiper(el, {
                direction: isMobile ? 'horizontal': 'vertical',
                allowTouchMove: false,
                modules: [Autoplay],
                // loop: true,
                slidesPerView: "auto",
                spaceBetween: isMobile ? -150 : 20,
                speed: 8000,
                autoplay: {
                    delay: 0,
                    disableOnInteraction: false
                },
                on : {
                    slideChangeTransitionEnd: (swiper) => {
                        // trigger offset +1 because of the overlapping horizontal cards on mobile
                        if(swiper.activeIndex === 2){
                            el.swiper.autoplay.stop();

                            MoveSlideToEnd(swiper);

                            el.swiper.autoplay.start();
                        }
                    }
                }
            });

            if(typeof el.swiper !== 'undefined'){
                el.addEventListener('mouseenter', ev => {
                    el.swiper.autoplay.stop();
                });
                el.addEventListener('mouseleave', ev => {
                    el.swiper.autoplay.start();
                });
            }
        }
    }
}