export const MoveSlideToEnd  = (swiper) => {

    let newSlides = Array.from(swiper.slides);
    let newLast = newSlides.shift();

    newLast.classList.remove('even', 'odd');
    newLast.classList.add(newSlides[newSlides.length - 1].classList.contains('even') ? 'odd' : 'even');

    newSlides.push(newLast);

    Array.from(newSlides).map((el, idx) => {
        el.classList.remove('swiper-slide-active', 'swiper-slide-active');
        return el;
    });

    swiper.$wrapperEl[0].innerHTML = "";
    newSlides.forEach(el => {
        swiper.$wrapperEl[0].insertAdjacentElement('beforeend', el)
    });

    swiper.slideTo(1, 0)
    swiper.update();
};