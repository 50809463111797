/* globals jQuery, wordpress_globals */

import "../sass/theme.scss";

import $ from 'jquery';
import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import MicroModal from 'micromodal';
import tippy from 'tippy.js';

import CookieService from "./modules/CookieService";
import Nav from "./modules/Nav";
import Home from './modules/Home';
import Accordion from "./modules/Accordion";
import LocationsSwiper from "./modules/LocationsSwiper";
import SliderSection from "./modules/SliderSection";

window.namespace = {
	init: function() {
		MicroModal.init({
			onShow: (modal) => {
				console.log('onShow')
				document.body.classList.add("modal-open");
			},
			onClose: (modal) => {
				console.log('onClose')
				document.body.classList.remove("modal-open");
			}
		});
		this.helpers.initSvg();

		setTimeout(() => {
			this.helpers.setScrollbarWidth();
			this.helpers.setNavBarHeight();
		}, 100);

		Nav.init();
		Home.init();

		// this.exitModal.init();
		this.fullMemberModal.init();

		//Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});

		if(document.querySelector('.accordion') !== null){
			document.querySelectorAll('.accordion').forEach(el => {
				el.accordion = new Accordion(el);
			})
		}

		document.body.addEventListener('filter.modal.open', ev => {
			document.scrollingElement.classList.add('filter-modal-open');
		});

		document.body.addEventListener('filter.modal.close', ev => {
			document.scrollingElement.classList.remove('filter-modal-open');
		});

		document.body.addEventListener('venueForm.completed', ev => {
			document.body.classList.remove('minimal-nav');
		});

		if(document.querySelector('.taxonomy-selector') !== null){
			document.querySelectorAll('.taxonomy-selector').forEach(el => {
				el.addEventListener('change', ev => {
					window.location = ev.currentTarget.value;
				});
			});
		}

		if(document.querySelector('.copy-link') !== null){
			document.querySelectorAll('.copy-link').forEach(el => {
				el.addEventListener('click', this.helpers.copyTextToClipboard.bind(this));
			});
		}

		if(document.querySelector('.collapse__button.non-react') !== null){
			document.querySelectorAll('.collapse__button.non-react').forEach(el => {
				el.addEventListener('click', ev => {
					ev.currentTarget.closest('.collapse').classList.toggle('collapse--open');
				});
			});
		}

		if(document.querySelector('.form__tooltip') !== null){
			document.querySelectorAll('.form__tooltip').forEach(el => {
				if(typeof el.dataset.content === 'undefined')
					return true;

				el.tippy = tippy(el, {
					content: `<p>${el.dataset.content}</p>`,
					trigger: 'click',
					allowHTML: true,
					placement: 'bottom'
				});
			})
		}

		if(document.querySelector('.section--locations-swiper') !== null){
			document.querySelectorAll('.section--locations-swiper').forEach(el => {
				el.locationsSwiper = new LocationsSwiper(el);
			});
		}

		if(document.querySelector('.section--slider') !== null){
			document.querySelectorAll('.section--slider').forEach(el => {
				el.section = new SliderSection(el);
			});
		}

		this.singleVenue.init();
	},
	exitModal: {
		init: function() {
			// MicroModal.init({});

			var pagesViewed = CookieService.getCookie('pages_viewed');

			if (pagesViewed === null) {
				pagesViewed = 1;
				CookieService.setCookie('pages_viewed', 1, 6000);
			}else{
				pagesViewed = parseInt(pagesViewed);
				pagesViewed++;

				if(pagesViewed <= 8){
					CookieService.setCookie('pages_viewed', pagesViewed, 6000);
				}
			}

			const exit = e => {
				const shouldExit =
				[...e.target.classList].includes('modal__overlay') || // user clicks on mask
				e.target.className === 'modal__close' || // user clicks on the close icon
				e.keyCode === 27; // user hits escape

				if (shouldExit) {
					MicroModal.close('exit-modal');
				}
			};

			const mouseEvent = e => {
				var shouldShowExitIntent =
				!e.toElement &&
				!e.relatedTarget &&
				e.clientY < 10;

				if(pagesViewed >= 5){
					shouldShowExitIntent = true;
				}

				// Don't interrupt the review process
				if(window.location.pathname == '/suggest-a-venue/' || window.location.pathname == '/allergy-aware-restaurant-register/'){
					shouldShowExitIntent = false;
				}

				if (shouldShowExitIntent) {
					document.removeEventListener('mouseout', mouseEvent);
					MicroModal.show('exit-modal');
					CookieService.setCookie('exitIntentShown', true, 45);
				}
			};

			if (!CookieService.getCookie('exitIntentShown')) {
				setTimeout(() => {
					document.addEventListener('touchmove', mouseEvent);
					document.addEventListener('mouseout', mouseEvent);
					document.addEventListener('keydown', exit);
				}, 0);
			}
		}
	},
	fullMemberModal: {
		init: function() {
			window.addEventListener('modal.fullmember.show', ev => {
				MicroModal.show('full-member-modal');
			});

			if(document.querySelector('.ac-member-link') !== null){
				document.querySelectorAll('.ac-member-link').forEach(el => {
					el.addEventListener('click', ev => {
						const event = new Event('modal.fullmember.show');
						window.dispatchEvent(event);
					});
				});
			}
		}
	},
	singleVenue: {
		init: function () {
			const affixedCTA = document.querySelector('#affixed-cta');
			const venueImage = document.querySelector('.venue-overview__image');

			if(document.body.classList.contains('single-venue') && affixedCTA !== null){
				document.addEventListener('scroll', ev => {
					const rect = venueImage.getBoundingClientRect();

					if((rect.top + rect.height) < 1){
						affixedCTA.classList.add('visible');
					} else {
						affixedCTA.classList.remove('visible');
					}
				}, { passive: true });
			}
		}
	},
	helpers: {
		setScrollbarWidth: function() {
			// Creating invisible container
			const outer = document.createElement('div');
			outer.style.visibility = 'hidden';
			outer.style.overflow = 'scroll'; // forcing scrollbar to appear
			outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
			document.body.appendChild(outer);

			// Creating inner element and placing it in the container
			const inner = document.createElement('div');
			outer.appendChild(inner);

			// Calculating difference between container's full width and the child width
			const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

			// Removing temporary elements from the DOM
			outer.parentNode.removeChild(outer);

			document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
			// return scrollbarWidth;
		},
		setNavBarHeight: function(){
			const navBar = document.querySelector('#site-nav');
			if(navBar !== null){
				const test = navBar.offsetHeight;

				document.documentElement.style.setProperty('--navbar', `${test}px`);
			}
		},
		fallbackCopyTextToClipboard: function(text) {
			var textArea = document.createElement("textarea");
			textArea.value = text;

			// Avoid scrolling to bottom
			textArea.style.top = "0";
			textArea.style.left = "0";
			textArea.style.position = "fixed";

			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();

			try {
				var successful = document.execCommand('copy');
				var msg = successful ? 'successful' : 'unsuccessful';
			} catch (err) {
				return false;
			}

			document.body.removeChild(textArea);

			return true;
		},
		copyTextToClipboard: function(event) {

			event.preventDefault();

			if(typeof event.currentTarget.dataset.href === 'undefined'){
				return false;
			}

			var target = event.currentTarget;
			var text = event.currentTarget.dataset.href;

			if (!navigator.clipboard) {
				if(this.helpers.fallbackCopyTextToClipboard(text)){
					target.classList.add('copied');
					setTimeout(function(){
						target.classList.remove('copied');
					},2000);
				}
				return;
			}

			navigator.clipboard.writeText(text).then(function() {
				target.classList.add('copied');
				setTimeout(function(){
					target.classList.remove('copied');
				},2000);
			}, function(err) {
				console.error('Async: Could not copy text: ', err);
			});
		},
		requireAll: function(r) {
			r.keys().forEach(r);
		},
		initSvg: function (){
			svgxhr({
				filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
			});
		}
	}

	// Prefer creating new modules when appropriate, rather than adding functions here
	// If you need something to be globally accessible, you can assign an imported module to this namespace
	// I.e import Contact from './contact';
	// window.namespace.Contact = Contact;
};

window.namespace.init();


if(typeof NodeList.prototype.forEach !== 'function'){
	NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
window.namespace.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
