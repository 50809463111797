export default {
    init: function() {
        const open = document.querySelector('#nav-trigger');
        if(open !== null){
            open.addEventListener('click', ev => {
                ev.preventDefault();

                document.documentElement.classList.add('menu-open');
            });
        }

        const close = document.querySelector('#nav-close');
        if(close !== null){
            close.addEventListener('click', ev => {
                ev.preventDefault();

                document.documentElement.classList.remove('menu-open');
            });
        }
    }
}