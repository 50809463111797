import Swiper, {Autoplay, Navigation} from 'swiper';

export default class SliderSection {
	constructor(element) {
		if(!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.element = element;

		this.init();
    }

    init(){
		if(this.element.querySelector('.card-swiper') !== null){
			this.element.querySelectorAll('.card-swiper').forEach(el => {
				el.swiper = new Swiper(el ,{
					modules: [Navigation],
					slidesPerView: "auto",
					spaceBetween: 20,
					navigation: {
						nextEl: this.element.querySelector('.next'),
						prevEl: this.element.querySelector('.prev'),
					},
				});
			});

			if(this.element.querySelector('.story') !== null && window.outerWidth > 767){
				this.initStoryEvents();
			}
		}
	}

	initStoryEvents() {
		this.element.querySelectorAll('.story').forEach(el => {
			el.addEventListener('mouseenter', ev => {
				const copyElement = ev.currentTarget.querySelector('.story__copy');

				if(copyElement !== null){
					let oldTrans = copyElement.style.transition;

					copyElement.style.transition = 'none';
					copyElement.style.height = 'auto';

					let height = copyElement.offsetHeight;
					copyElement.style.height = '0';

					setTimeout(() => {
						copyElement.style.transition = oldTrans;
						copyElement.style.height = `${height}px`;
					}, 10);
				}
			});

			el.addEventListener('mouseleave', ev => {
				const copyElement = ev.currentTarget.querySelector('.story__copy');

				if(copyElement !== null){
					copyElement.style.height = '0';
				}
			});
		});
	}
}