import Swiper, { Autoplay } from 'swiper';
import { MoveSlideToEnd } from "./MoveSlideToEnd";

export default class LocationsSwiper {
    constructor(element) {
		if(!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.element = element;

		this.initSwipers();
    }

    initSwipers() {
        if(this.element.querySelector('.locations-swiper') !== null){
            this.element.querySelectorAll('.locations-swiper').forEach((el, idx) => {
                el.swiper = new Swiper(el, {
                    modules: [Autoplay],
                    allowTouchMove: false,
                    slidesPerView: "auto",
                    speed: (14000 + (Math.floor(Math.random() * 5) * 500)),
                    autoplay: {
                        delay: 0,
                        disableOnInteraction: false
                    },
                    on : {
                        slideChangeTransitionEnd: (swiper) => {
                            // trigger offset +1 because of the overlapping horizontal cards on mobile
                            if(swiper.activeIndex === 2){
                                el.swiper.autoplay.stop();

                                MoveSlideToEnd(swiper);

                                el.swiper.autoplay.start();
                            }
                        }
                    }
                });
            });
        }
    }
}